<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Pendaftaran Hak </strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputDaftar">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="nama_instansi"
                label="Nama Instansi"                
                placeholder="Input nama instansi"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                type="date"
                v-model="tgl_pendaftaran"
                label="Tanggal Pendaftaran"
                placeholder="Input tanggal pendaftaran"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="jumlah_bidang"
                label="Jumlah Bidang"
                placeholder="Input jumlah bidang"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nama_pendaftar"
                label="Nama Pendaftar"
                placeholder="Input nama pendaftar"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="no_hp"
                label="Nomor Telepon/Hp"
                placeholder="Input nomor telepon/hp"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="kelurahan"
                label="Kelurahan"
                placeholder="Input Kelurahan"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="no_hak"
                label="Nomor Hak"
                placeholder="Input Nomor Hak"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="su"
                label="SU"
                placeholder="Input SU"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nib"
                label="NIB"
                placeholder="Input NIB"
              />  
              </CCol> 
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Daftar Alas Hak</CButton>
                  <CButton color="secondary" to="/dashboard">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      nama_instansi : "",
      tgl_pendaftaran : "",
      jumlah_bidang : "",
      nama_pendaftar : "",
      no_hp : "",
      no_hak : "",
      su : "",
      nib : "",
      kelurahan : "",
      selected: [], // Must be an array reference!
      show: true,
    }
  },
  methods: {
    
    inputDaftar: function(event){
      // Simple POST request with a JSON body using axios
      const daftar = { 
                          nama_instansi:this.nama_instansi,
                          tgl_pendaftaran: this.tgl_pendaftaran, 
                          jumlah_bidang: this.jumlah_bidang, 
                          nama_pendaftar: this.nama_pendaftar, 
                          no_hp: this.no_hp, 
                          kelurahan: this.kelurahan, 
                          no_hak: this.no_hak, 
                          su: this.su, 
                          nib: this.nib
                          };
      // alert(JSON.stringify(subrak));
      axios.post(process.env.VUE_APP_BASE_URL+"addasetbersertifikat", daftar)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/dashboard');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>